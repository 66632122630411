<template>
  <div>
    <v-file-input
      v-model="file"
    >
    </v-file-input>
    <v-btn
      color="success"
      @click="debong"
    >
      DEBOGNG
    </v-btn>
    <v-file-input
      v-model="file2"
    >
    </v-file-input>
    <v-btn
      color="success"
      @click="debong2"
    >
      DEBOGNG2
    </v-btn>
  </div>
</template>

<script>
import Papa from 'papaparse'

export default {
  data: () => ({
    file: [],
    file2: [],
    file2parsed: [],
    fileParsed: [],
    fileObjectified: [],
  }),
  mounted() {
  },
  methods: {
    debong() {
      Papa.parse(this.file, {
        complete: results => {
          this.fileParsed = results.data
          this.fileParsed.forEach(line => {
            this.fileObjectified.push({
              apen: line[0],
              intape: line[1],
              effarrdiff: line[2],
              idcc: parseInt(line[3], 10),
              pctdiff: line[4],
            })
          })
        },
      })
    },
    debong2() {
      Papa.parse(this.file2, {
        complete: results => {
          this.file2parsed = results.data
          this.file2parsed.forEach(line => {
            line[0] = parseInt(line[0], 10)
          })
          this.fileObjectified.forEach((line, index) => {
            const type = typeof (line.idcc)
            if (type === 'number') {
              const Opco = this.findOpco(line.idcc)
              if (Opco) {
                // eslint-disable-next-line prefer-destructuring
                line.opeateurdecompetence = Opco[1]
                // eslint-disable-next-line prefer-destructuring
                line.libelle = Opco[2]
                // eslint-disable-next-line prefer-destructuring
                line.obs = Opco[3]
              }
            } else {
              console.log(index)
            }
          })
          console.log(this.fileObjectified)
        },
      })
    },
    findOpco(idcc) {
      const matchingLine = this.file2parsed.find(line => line[0] === idcc)

      return matchingLine
    },

  },
}
</script>

<style>

</style>
